.modal {
    .modal-header {
        padding: 1rem;

        .modal-title {
            font-weight: bold;
            font-size: 24px;
            line-height: 21px;
            color: map-get($theme-colors, "dark");
        }
        .close {
            margin: 0;
            padding: 0;
        }
    }
    .modal-content {
        border: none;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
    }
}
.fade {
    background: rgba(242, 244, 244, 0.95);
}
