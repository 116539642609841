.importItem {
    border: 1px solid $input-border;
    border-radius: 5px;
    padding: 1.4rem;

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 1.4rem;
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: map-get($theme-colors, "primary");
            float: left;
            margin-right: 1.4rem;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            color: #fff;
        }
        .title {
            font-weight: bold;
            color: map-get($theme-colors, "dark");
            display: flex;
            align-items: center;
            .help {
                margin-left: 3px;
                height:16px;
                color: map-get($theme-colors, "secondary");
                i {
                    font-size: 16px;
                }
            }
        }
        .attachment {
            color: $grey-4;
            font-size: 90%;
            text-decoration: underline;
        }
    }
    .file-button {
        display: flex;
        justify-content: center;

        .btn-primary {
            input[type="file"] {
                display: none;
            }
        }
    }
}
.history-import{
    color:#747E8B;
    .progress{
        margin: auto;
        background-color: #E9ECEC;
        border-radius: 3px;
        color: #C4C4C4;
        text-transform: lowercase;
        margin-top: 10px;
   
        .progress-bar{     z-index: 2;}

       small{
           margin-left: 2px;
           position: absolute;
           margin-left: 3%;
           z-index: 1;
       }
       i{
           position: absolute;
           right: 16.5%;
           font-size: 14px;
       }
    }
}
#details{
    color:#747E8B;
    .download-archive{
        position: absolute;
        margin-top: -5%;
        right: 3%;
        color: #4B5F79;
        span{
            font-size: 19px;
            padding-top: 3px;
            position: absolute;
            margin-left: -21px;
        }
    }
    .log-erro{
        
        i{
            font-size: 14px;
        }
        a{
            margin-left: 10px;
            color: #C4C4C4;
            font-size: 12px;
            text-decoration:underline

        }
    }
}
.bg-primary{
    background-color: $primary-color !important;
}
.import{
    a{
        margin-left: -14px;
        text-decoration:underline;
    }
    .import-details-body{
        margin-top: 10px;
       padding: 3%;
       border-radius: 5px;
       background: #E9ECEC;
       font-family: 'Roboto Mono', monospace;
       p{
           font-size: 14px;
           padding: 0;
           margin: 0;
           color:#4B5F79;
       }
    }
}
