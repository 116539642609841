.download-app {
  padding-top: 2rem;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #e5e5e5;
  position: relative;
  padding-bottom: 50px;

  .download-body {

    .download-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.5rem;


      .app-icon {
        border-radius: 15px;
        margin-bottom:1.5rem;
      }

      h3 {
        padding-top: 5px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
      }

      .text-description {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        padding: 0px 70px;
        margin-bottom: 20px;
        color: #4b5f79;
      }
    }
    .download-link {
      max-width: 600px;

      .download-buttons {
        text-align: center;
        a {
          display: inline-block;
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }

      .download-qr {
        border-right: 2px solid #c4c4c4;
      }

      .qrcode {
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        padding: 10px 10px 5px 10px;
      }

      .text-qr {
        position: relative;
        &::before {
          display: block;
          height: 100%;
          width: 2px;
          content: "";
          position: absolute;
          background-color: #c4c4c4;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        span {
          position: absolute;
          display: block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          font-size: 18px;
          line-height: 18px;
          font-weight: 400;
          background-color: #e5e5e5;
          padding: 6px 0 10px 0;
        }
      }
    }
  }
  .download-footer {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 14px;
    color: #4b5f79;

    img {
      height: 15px;
    }
  }
}
