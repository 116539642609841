.card {

  border: 1px solid $input-border;

  .card-header {
    padding: 1.4rem;
    background-color: transparent;
    position: relative;
    border-bottom: 0;
    //display: flex;

    .card-title {
      margin: 0;
      font-weight: bold;
      font-size: 24px;
      color: map-get($theme-colors, "dark");
    }
  }
  .card-body {
      padding: 1.4rem;
      &.no-padding {
          padding:0;
      }

  }
}

