.material-card{
  background: #fff;
  cursor: pointer;
  border-color: #EEEEEE;
  height:100%;
  padding:15px;
  display: flex;
  align-items: center;
  text-align: left;
  @include card-default;

  .icon {
    margin-right:15px;
  }

  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
  }
    i {
      background-color:  #ECECEC;
      color:  #80868B;
      padding:10px;
      border-radius: 5px;
    }
}
.card-title{
  font-size: 16px;
  font-weight: 700;
}
.card-colorpicker{
  @include card-default;
  height: 150px;
  width: 145px;
  margin: 10px auto;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  .color-name {
    position: absolute;
    bottom: 40px;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
  }

  .color-label {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: $white;
    text-align: center;
    padding: 6px;
    font-weight: 700;
  }
}
.card-logo{
  @include card-default;
  height: 150px;
  width: 300px;
  margin: 10px auto;
  position: relative;
  cursor: pointer;
  

  .logo-upload {
    .box-img {
      border-radius:5px 5px 0 0;
    }
  }
  .logo-label {
    position: absolute;
    bottom: 2px;
    width: 100%;
    background: $white;
    text-align: center;
    padding: 6px;
    font-weight: 700;
  }
}