.page-navigation {
    .results {
        color: map-get($theme-colors, "dark");
    }
    .pagination {
        margin: 0;

        .page-item {
            &.disabled {
                display: none;
            }

            .page-link {
                border: none;
                color: map-get($theme-colors, "dark");
                padding: 0 0.65rem;
                line-height: 24px;
                &:hover {
                    background: transparent;
                }
            }
            &.active {
                .page-link {
                    font-weight: bold;
                    background: transparent;
                    color: map-get($theme-colors, "dark");
                }
            }
        }
    }
}
