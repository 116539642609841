@font-face {
  font-family: Blokk;
  src: url('/fonts/BLOKK.otf');
}
.preview{
  width: 300px;
  margin:auto;
  background-color: #fff;
}
.barra{
  height: 70px;
  width: 300px;
}
.banner-app{
  width: 300px;
  height: 172px;
  background-color:  #F3F2F2;
}
.banner-bot{
  width: 300px;
  height: 172px;
  background-color:  #fff;
}
.banner-app-2{
  height: 147px;	
  width: 277px;	
  border-radius: 3px;	
  background-color: #F3F2F2; 
  margin: auto; 
  margin-top: 14px;
  margin-bottom: 26px;
}
.rectangle-copy {	
  height: 58px;	
  width: 277px;	
  border-radius: 3px;	
  background-color: #F3F2F2; 
  margin: auto; 
  margin-top: 14px;
}
.footer-app{
  padding:3%;
  height: 50px;	
  width: 300px;	
  margin: auto;
  background-color: #F3F2F2;
  	box-shadow: 0 -1px 0 0 #E7E7E7;
}
.brand-colors {
  width:100%;
}
.preview {
  .preview-app, .preview-email, .preview-shopbot {
    border:1px solid #E7E7E7;
    overflow: hidden;
  }
}

.block-font{
  font-family: Blokk;
}
.text-rect{
  margin-top: -4%;
  margin-left: -4%;
}
.tab-preview{
   width: 330px;
   margin: auto;
   margin-bottom: 28px;
   background-color: #fff;
   border: 1px solid #D0D0D0;
   border-radius: 5px;
   padding: 5px;
}
.tab-preview2{
  width: 355px;
  margin: auto;
  margin-bottom: 28px;
  background-color: #fff;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  padding: 5px;
}
.chat{
  height: 46px;	
  width: 197px;	
  border-radius: 5px;	
  background-color: #F3F2F2;
  margin-left: 15px;
  margin-bottom: 15px;
}
.chat-right{
  margin-left: 92px;
}
.footer-shopbot{
  height: 46px;	
  width: 300px;	
  background-color: #FFFFFF;	
  box-shadow: 0 -1px 0 0 rgba(0,0,0,0.09);
}
.send-icon{
  margin-top: 10px;
  margin-right: 10px;
}
.email{
  height: 78px;
    width: 78px;	
    background-color: #F3F2F2;
    border-radius: 78px;
    margin: auto;
    margin-top: 30px;
}
.rectangle-email {	
  height: 13px;	
  width: 160px;	
  border-radius: 3px;	
  background-color: #F3F2F2; 
  margin: auto; 
  margin-top: 12px;
}
.rectangle-email2{	
  height: 13px;	
  width: 232px;	
  border-radius: 3px;	
  background-color: #F3F2F2; 
  margin: auto; 
  margin-top: 12px;
}
.nav-link.active-tab{
  background-color: 
  #00C3B6;
  color: #fff;
}
.nav-link{
  color: #4A4A4A;
}
.logos {
  width:100%;
}
.logo-upload {
  position: relative;
  
  .box-img{
    @include card-default;
    overflow: hidden;
    cursor: pointer;  
  }

  .close-img {
    position: absolute;
    top: -10px;
    right:-10px;
    border: none;
    background-color: #fff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    padding: 0;
    /* opacity: 0.3; */
    cursor: pointer;
    border: 1px solid #EFEFEF;

    &:hover {
      opacity: 1;
    }
    &:before, &:after {
      position: absolute;
      content: ' ';
      height: 10px;
      padding: -8px;
      width: 2px;
      margin-top: -5px;
      margin-left: -1px;
      background-color: #BBBDC0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  .close-img-carrousel {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: #FF5B5C;
    color: #fff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    padding: 0;
    /* opacity: 0.3; */
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
    &:before, &:after {
      position: absolute;
      content: ' ';
      height: 10px;
      padding: -8px;
      width: 2px;
      margin-top: -5px;
      margin-left: -1px;
      background-color: #fff;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}


.text-logo{
  padding-top: 1.5%;
}
.text-icon-logo{
  padding-top: 10%;
}