//Colors
$bg-color: #F2F4F4;
$primary-color: #01BFB5;
$secondary-color: #6D4AED;
$success-color:#6CD691;
$text-menu: #747E8B;
$white: #ffffff;

$grey-h: #474162;
$grey-1: #EAEAEB;
$grey-8: #4A4A4A;
$grey-4: #8D8D8D;

$input-border: #dee2e6;

/* Theme Colors */
$theme-colors: (
    "primary":  #01BFB5,
    "secondary":#6D4AED,
    "success":  #6CD691,
    "danger":   #FF5B5C,
    "warning":  #FDAC41,
    "info":     #7A9FEB,
    "light":    #E9ECEC,
    "dark":     #4B5F79,
    "white":    #ffffff,
    "link":     transparent,
    
);

/* Common variables*/

/* Autosetup variables prefix 'as' */
$as-bg-color: $bg-color;
$as-primary-color: $primary-color;

/* Panel variables prefix 'pn' */
