.badge {
    border-radius: .50rem;
    padding: .45em 1.5em;
    font-weight: normal;
    border-radius: 15px;

    &.stadium {
        border-radius: 3px;
    }
}

@include badge("primary", false);
@include badge("secondary", false);
@include badge("success", false);
@include badge("danger", false);
@include badge("warning", false);
@include badge("info", false);
@include badge("light", true);
@include badge("dark", false);