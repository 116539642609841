.stores {
  width:100%;
}


.empty{
  margin:auto;
  padding: 5%;
  width: 100%;
}

.buttons-outlet{
  margin-top: 26px;
}
.add-appstore-button{
  background-color: #01BFB5;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 12px;
  margin-left: -25px;
  margin-top: 10px;
  padding-left: 34px;
  padding-right: 34px;
  cursor: pointer;

  &:hover{
    opacity: 0.8;
  }
}

.add-outlet-button{
  background-color: #01BFB5;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 12px;
  padding-left: 34px;
  padding-right: 34px;
  margin-left: 20px;
  cursor: pointer;
  
  &:hover{
    opacity: 0.8;
  }
}
.download-model{
  position: absolute;
    bottom: -30px;
    margin-left: -19%;
    font-size: 13px;
    color: #666;
}

.table-crud{
  text-transform: capitalize;
  th{
    border:none;
    font-weight: 300;
    color: #B1B1B1;
    }
}
.btn-group-crud{
  color: #424242;
  border: 1px solid #EEEEEE;
}
.second{
  margin-top: 50px;
}
.horarios{
  margin-left: 20px;
}