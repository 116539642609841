.btn {
  padding:.55rem 1rem;
  font-size: .875rem;
  transition: all .5s ease;

  //Button small style
  &.btn-sm {
    font-size: .675rem;
    i {
      font-size: 1rem;
    }
  }

  //Button large style
  &.btn-lg {
    font-size: 1.15rem;
  }

  // Remove bootstrap focus
  &:focus {
    outline: 0;
    box-shadow: none;
  }

  // Round Style
  &.round {
    border-radius:20px;
  }

  // Shadow Style
  &.shadow {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2)!important;
    &:hover {
      box-shadow: 0 4px 12px 0 rgba(0,0,0,.2)!important;
    }
  }

  &.btn-icon-r, &.btn-icon-l {
    position: relative;
    i {
      float:left;
      margin-top:2px;
      font-size: 1.2rem;
      margin-right:10px;
    }
  }

  &.btn-icon-c-r, &.btn-icon-c-l {
    position:relative;
    i {
        position: absolute;
        font-size: 18px;
        padding: 3px;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        line-height: 22px;
        text-align: center;
        top:50%;
        transform: translate(0, -50%);
    }
  }
  &.btn-icon-c-l {
    padding-left: calc(.55rem + 38px);
    i {
        left: .55em;
    }
  }
  &.btn-icon-c-r {
    padding-right: calc(.55rem + 38px);
    i {
        right: .55em;
    }
  }
  &.btn-icon {
    display: inline-flex;
    align-items: center;
  }
  &.btn-action {
    background-color: transparent !important;
    &:hover {
      background-color: transparent !important;
    }
  }

  &.dropdown-toggle {
    &.no-txt {
      &::after {
        @extend .m-icon;
        font-size: 16px;
        width: auto;
        height: auto;
        margin:0;
        display: flex;
        content: "keyboard_arrow_down";
        border:0;
      }
    }
  }
}

@include button("primary", false);
@include button("secondary", false);
@include button("success", false);
@include button("danger", false);
@include button("warning", false);
@include button("info", false);
@include button("light", true);
@include button("dark", false);
@include button("link", true);
@include button("white", true);

.btn-default {
  background: $white;
  border:1px solid $grey-1;

  &:hover {
      background: darken($white, 5%);
      &.btn-icon-r, &.btn-icon-l{
          i {
              background: darken($white, 10%);
          }
      }
  }
  &.btn-icon-r, &.btn-icon-l {
      i {
          background: darken($white, 5%);
      }
  }
}

  /* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
      }
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $input-border;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
  }
  
  input:checked + .slider {
    background-color: map-get($theme-colors, "primary");
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px map-get($theme-colors, "primary");
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }