.stepper {
  position: fixed;
  z-index: 100;
  width: 100%;
  bottom: 0;
  left: 0;
  background: $white;
  padding: 10px 0;
  border-top: 1px solid #eeeeee;

  .container {
    position: relative;
  }

  .skip-step{
    position: absolute;
    bottom: 60px;
    right: 15px;
    color: #80868B;
    &:hover{
      color: #80868B
    }
  }

  .timeline {
    height: 40px;
    display: flex;
    position: relative;
    .btn-light, .btn-primary {
      width: 100px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      width: calc(100% - 200px);
      position: relative;
      overflow: hidden;

      &::before {
        width: 100%;
        height: 3px;
        background: #efefef;
        content: "";
        display: block;
        position: absolute;
        top: 7px;
      }
      li {
        font-size: 12px;
        text-align: center;
        flex: 1;
        position: relative;

        span {
          color: #9b9b9b;
          display: block;
          margin-top: 20px;
        }

        &::before {
          position: absolute;
          width: 18px;
          height: 18px;
          border-radius: 9px;
          display: block;
          content: "";
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          border: 2px solid #efefef;
          background: #fff;
        }
        &.completed {
          &::before {
            border: 2px solid $primary-color;
            background: $primary-color;
          }
          &::after {
            width: 100%;
            height: 3px;
            background: $primary-color;
            content: "";
            display: block;
            position: absolute;
            top: 7px;
          }
          span {
            color: $primary-color;
            font-weight: bold;
          }
        }
        &.active {
          &::before {
            border: 2px solid #efefef;
            background: $secondary-color;
            z-index: 2;
          }
          &::after {
            width: 50%;
            height: 3px;
            background: $primary-color;
            content: "";
            display: block;
            position: absolute;
            top: 7px;
            z-index: 1;
          }
          span {
            color: $secondary-color;
            font-weight: bold;
          }
        }
      }
    }
  }
}

