.preview {
    &.app {
        background: $input-border;
        box-shadow: 0 0 0 5px $input-border;
        border: 1px solid darken($input-border, 5%);
        border-radius:35px;
        padding:7px;
        overflow: hidden;
        width:266px;
        .body {
            width:250px;
            height:520px;
            background: $white;
            border-radius: 25px;
            overflow: hidden;
            overflow-y: auto;
            position: relative;
        }
        .app-bar {
            padding-top:25px;
            background-color: $input-border;
            height:65px;
            display: flex;
            align-items: center;
            position: absolute;
            top:0;
            width:100%;

            [class^='action-'] {
                flex: 1;
                display: flex;
                color: $white;
            }
            .action-left {
                padding-left: 10px;
                justify-content: flex-start;
            }
            .action-center {
                justify-content: center;
            }
            .action-right {
                justify-content: flex-end;
                padding-right: 10px;
            }
        }
    }
    .bottom-navigation {
        position: absolute;
        bottom: 0;
        width:100%;
        background: $white;
        display: flex;
        align-items: center;
        height:45px;
        padding-bottom:15px;
        padding-top:5px;
        border-top: 1px solid $input-border;
        
       .action-item {
            flex: 1;
            color: $grey-4;
            text-align: center;
            .material-icons {
                font-size: 20px;
                margin:0;
                display: block;
                line-height: 18px;
            }
            .label {
                display:block;
                font-size: 8px;
            }
        }
    }
    .app-content {
        position: absolute;
        top:65px;
        bottom: 45px;
        width:100%;
    }
    .app-carousel-banner {
        height: 160px;
        background: $input-border;
        position: relative;
        .carousel-inner {
            height:100%;
            .carousel-item {
                height: 100%;
                .banner {
                    width:100%;
                    height:100%;
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }
    .app-list-banners {
        padding:10px;
        ul {
            padding:0;
            margin:0;
            list-style-type: none;
            li {
                margin-bottom:10px;

                .banner {
                    height: 120px;
                    background-size: cover;
                    background-position: center;
                    border-radius:3px;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.15);
                }

                &:last-child {
                    margin-bottom:0;
                }
                
            }
        }
    }
}
.tutorial-title{
   color:#fff;
   text-align:center;
   padding-top:21px;
   line-height: 16px;
   width: 152px;
   margin: auto;
   font-size: 14px;
    span{
        font-size:35px;
        margin-bottom:20px;
    }
}
.tutorial-content{
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    padding:19px;
}