
  /* Tablets */
  @media (max-width: 1000px) {
    .App {
      max-width: 600px;
    }
    .file-item {
      width: calc(50% - 22px);
      height: 200px;
    }
  }
  
  /* Mobiles */
  @media (max-width: 640px) {
    .App {
      max-width: 100%;
      padding: 0 15px;
    }
    .file-item {
      width: calc(100% - 22px);
      height: 200px;
    }
  }
  