.sidebar {
  a {
    color: #747e8b;
    text-decoration: none;
  }
  ul {
    padding-left: 0;
  }
  li {
    list-style-type: none;
    display: flex;
    cursor: pointer;
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: transparent;
    a {
      width:100%;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      display: flex;
      align-items: center;
    }
    &:hover, &.active {
      border-left-color: $primary-color;
      color: $primary-color;
    }
  }

  i {
    margin-right: 10px;
  }
}
.channel {
  padding: 20px;

  .active {
    border-left: 3px solid $primary-color;
    color: $primary-color;
  }
}
.csv {
  input[type="file"] {
    display: none;
  }
  cursor: pointer;
}
.upload {
  label {
    margin-bottom: 2.5px;
    cursor: pointer;
  }
}
.new-question {
  background: #ffffff;
  border: 1px dashed #e9ecec;
  box-sizing: border-box;
  border-radius: 5px;
  color: #4b5f79;
  padding: .55rem 1rem;
  cursor: pointer;
  width: 100%; 
}
.title-card {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #4b5f79;
}