
// .basic-info{
//   margin-top: 5%;
//   .form-group > input {
//       width: 45%;
//       margin: auto;
//   }
//   .form-group > label{
//     margin-left:27.5%;
//     margin-top: 15px;
//   }
// }
 .basic-info {
     width:100%;
 }
