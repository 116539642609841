.empty .list {
  padding: 10%;
}
.modal-header {
  padding-bottom: 0;
}
.modal-header,
.modal-footer {
  border: none;
}
.conditions {
  padding: 0px;
  height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px $secondary-color;
    border-radius: 10px;
    background-color: $bg-color;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $bg-color;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 6px $secondary-color;
    background-color: #999;
  }
  ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    li {
      padding: 1rem;
      border-bottom: 1px solid $input-border;
      font-size: 16px;
      line-height: 18px;
      color: map-get($theme-colors, "dark");
      cursor: pointer;
    }
  }
}
.search-list {
  .checkbox-list {
    height: 400px;
  }
}
.checkbox-list {
  padding: 0px;
  max-height: 400px;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px $secondary-color;
    border-radius: 10px;
    background-color: $bg-color;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $bg-color;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 6px $secondary-color;
    background-color: #999;
  }

  ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    li {
      padding: .5rem 1rem;
      font-size: 16px;
      line-height: 18px;
      color: map-get($theme-colors, "dark");
      cursor: pointer;
      border: 0;
    }
  }
}
