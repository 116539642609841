.breadcrumb{
    background-color: transparent;
    margin-bottom: 0px;
    margin-top: .5rem;
    padding: .75rem 0;
    .breadcrumb-item{
        color: $grey-4;
        &.active{
            color: map-get($theme-colors, 'secondary' );
        }
       
    }
} 
.breadcrumb-item+.breadcrumb-item::before{
    @extend .m-icon;
    content: "keyboard_arrow_right";
    font-size: 14px;
    color: $grey-4;
}