.materials{
  text-align:center;
  width:100%;
  
  h1{
    font-weight: 200;
  }
}
.materials-card{
  cursor: pointer;
  &:hover{
    border-bottom: 3px solid #01BFB5;
    margin-bottom: -3px;
  }
  i{
    background-color:  #ECECEC;
    color:  #80868B;
    padding:10px;
    border-radius: 5px;
  }
}
.card-title{
  font-size: 16px;
  font-weight: 700;
}
