.form-group {
    &.icon-right {
        i {
            position: absolute;
            top: 8px;
            right: 20px;
            color: #c4c4c4;
        }
    }
}
.form-control {
    border: 2px solid $input-border;
    color: map-get($theme-colors, "dark");
    font-size: 14px;
    &:active,
    &:focus {
        border-color: map-get($theme-colors, "secondary");
        box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, "secondary"), 0.25);
        color: map-get($theme-colors, "dark");
    }
    &::placeholder {
        color: rgba(map-get($theme-colors, "dark"), 0.4);
    }
}
.input-group {
    .input-group-text {
        color: map-get($theme-colors, "dark");
        background: $input-border;
        border: 0;
    }
}

.custom-checkbox {
    .custom-control-label {
        &::before,
        &::after {
            top: 50%;
            transform: translateY(-50%);
        }
        &::before {
            border: 2px solid #dee2de;
            background-color: #fff;
        }
    }
    .custom-control-input {
        &:checked ~ .custom-control-label::before {
            background: map-get($theme-colors, "success");
            border-color: map-get($theme-colors, "success");
        }
    }
}

.autocomplete {
    position: relative;
    .search {
        &:focus {
            border-color: $input-border;
            box-shadow: none;
        }
    }
    &.icon-right {
        i {
            position: absolute;
            top: 8px;
            right: 20px;
            color: #c4c4c4;
        }
    }
    .result {
        width: 100%;
        height: 150px;
        margin-top: -2px;
        overflow-y: scroll;
        position: absolute;
        background-color: #ffff;
        padding: 0;
        border: 2px solid $input-border;
        border-radius: 0px 0px 5px 5px;
        z-index: 999;
        ul {
            list-style-type: none;
            padding: 0;
        }
        li {
            border-bottom: 1px solid $input-border;
            padding: 10px;
            cursor: pointer;
        }
        li:last-child {
            border-bottom: none;
        }
    }
    .neo-loading {
        position: absolute;
        right: 10px;
        width: 30px;
        height: 30px;
    }
}
.rw-multiselect {
    padding: 0;
    height:auto;
    border:0;

    &.rw-state-focus, &.rw-state-focus:hover {
        .rw-widget-container {
            box-shadow: none;
            border: 2px solid $input-border;
        }
    }

    .rw-widget-input {
        box-shadow: none;
        border: 2px solid $input-border;
    }
    .rw-widget-picker {
        position: relative;
        > * {
            display: flex;
            align-items: center;
        }
        .rw-select {
            position: absolute;
            right: 10px;
            top: 0;
        }
        .rw-multiselect-taglist {
            padding: 0.25rem;
            padding-bottom:0;

            .rw-multiselect-tag {
                background: map-get($theme-colors, "secondary");
                border: 0;
                color: map-get($theme-colors, "white");
                margin-top: 0;
                margin-left: 0;
                margin-right: 0.25rem;
                margin-bottom: .25rem;

                .rw-btn-select {
                    opacity: 1;
                }
            }
        }
    }
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    height: 300px !important;
}

.grouped-input-before{
    h6{
        position: absolute;
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 600;
        color: #b3b3b3;
        opacity: 0.8;
    }
    input{
        padding-left: 35px;
    }
}
.grouped-input-after{
    h6{
        position: absolute;
        margin-top: -6%;
        right: 25%;
        font-weight: 600;
        color: #b3b3b3;
        opacity: 0.8;
    }
    input{
        padding-left: 35px;
    }
}
.section-step{
    margin-left: 20px;
    width: 100%;
    margin-bottom: 50px;
    .number{
        position: absolute;
        left: 20px;
        /* margin-right: 10px; */
        /* padding-right: 20px; */
        background: $secondary-color;
        width: 30px;
        height: 30px;
        font-size: 18px;
        border-radius: 50%;
        text-align: center;
        color: #fff;
    }
    h3{
        margin-left: 20px;
        font-weight: bold;
        font-size: 20px;
        color: #4B5F79;
    }
}