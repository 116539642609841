.welcome{
  text-align: center;
  p{
    width: 69%;
    margin: auto;
    margin-top: 30px;
    font-size: 18px;
  }
}
.lori > img{
  width: 60%;
  margin-left: 25%;
}