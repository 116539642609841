.header {
  &.header-primary {
    padding: 0;
    background-color: map-get($theme_colors, "secondary");
    position: relative;

    .navbar-toggler {
      border-color: lighten(map-get($theme_colors, "secondary"), 5%);
      color: map-get($theme_colors, "white");
      display: flex;
      align-items: center;
      
      .material-icons {
        font-size: 20px;;
      }
      
    }
    .navbar-wrapper {
      margin-left: auto;
    }

    .brand {
      display: inline-block;
      position: absolute;
      left:50%;
      top:50%;
      transform: translate(-50%, -50%);
    }

    .dropdown-user {
      .dropdown-menu-right {
        right: 12px;
        left: auto;
        padding: 0.5rem 0;
      }

      .dropdown-user-link::after {
        display: none;
      }
      .user-nav {
        margin-right: 0.6rem;
        text-align: right;
        .user-name {
          display: block;
          font-weight: 700;
        }
        .user-role {
          font-size: 0.85rem;
        }
      }
      .user-avatar {
        img {
          border-radius: 20px;
          height: 40px;
          width: 40px;
        }
      }
    }
    .nav-link {
      color: map-get($theme_colors, "white");
    }
    .dropdown-menu {
      .dropdown-item {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.6rem 1.6rem;
        clear: both;
        color: map-get($theme_colors, "dark");
        text-align: inherit;
        background-color: transparent;
        border: 0;
        &:hover {
          background-color: rgba(map-get($theme-colors, "dark"), 0.05);
        }
      }
    }
    .navbar-header {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 0;
      z-index: 1000;
      .navbar-brand {
        margin-right: 0;
      }
    }
    .dropdown-notification {
      .material-icons {
        font-size: 28px;
      }
      .badge-up {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 16px;
        height: 16px;
        text-align: center;
        padding: 0;
        line-height: 16px;
      }
    }
  }
}

.navbar {
  &.navbar-primary {
    padding: 0;
    background-color: map-get($theme_colors, "secondary");

    
  }
}

.horizontal-menu {
  background-color: $bg-color;
  border-bottom: 1px solid $input-border;
  padding: 0;
  &.navbar-expand-lg {
    .navbar-nav {
      & > li {
        padding: 10px 0;
      }
      li {
        i {
          width: 1.6rem !important;
          margin-right: 0.4rem;
        }

        & > a.nav-link {
          color: $text-menu;
          font-size: 0.9rem;
          border-radius: 4px;
          padding-right: 0.85rem;
          padding-left: 0.85rem;
          display: flex;
          align-items: center;
          height: 100%;
          transition: all 0.5s ease;
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &:hover {
            background-color: map-get($theme-colors, "light")
          }
        }
        &.show > a.nav-link {
          background-color: map-get($theme-colors, "light");
        }

        &.dropdown {
          cursor: pointer;
          .dropdown-toggle {
            &:after {
              content: "keyboard_arrow_down";
              font-family: "Material Icons";
              font-weight: normal;
              font-style: normal;
              font-size: 16px;
              line-height: 1;
              border: 0;
              height: auto;
              margin: 0 0.55rem;
            }
          }
          .dropdown-menu {
            border: 1px solid $input-border;
            padding: 0.7rem 0;
            top: calc(100% - 10px);
            .nav-link {
              padding-right: 2rem;
              white-space: nowrap;
              cursor: pointer;
              i {
                width: auto;
                right: 0;
                margin: 0;
              }
            }
            .dropdown-item {
              font-size: 0.9rem;
              padding: 0.7rem 1.3rem;

              &:hover {
                background-color: rgba(map-get($theme-colors, "light"), 0.4);
              }
            }
          }
          .dropdown-submenu {
            > a.dropdown-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 0;
              display: inline-block;
              color: #747e8b;
              font-weight: normal;
              font-style: normal;
              margin: 0 0.55rem;

              &:active {
                background-color: rgba(map-get($theme-colors, "light"), 0.4);
                color: map-get($theme-colors, "dark");
              }

              &:after {
                content: "keyboard_arrow_right";
                font-family: "Material Icons";
                font-weight: normal;
                font-style: normal;
                font-size: 16px;
                line-height: 1;
                border: 0;
                height: auto;
                margin: 0 0.55rem;
              }
            }
            .nav-link:hover ul {
              display: block;
            }
            .dropdown-menu {
              left: 100%;
              top: 0;
            }
          }
        }
      }
    }
  }
}

.dropright-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
  color: #747E8B;
  font-weight: normal;
  font-style: normal;

  .material-icons {
    font-size: 16px;
    font-weight: normal;
  }
}

.dropdown-submenu {
  display: flex;  
}

.dropright {
  list-style: none;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  margin-top: 30px;
  margin-left: -450px;
  padding: 5px;
  background-color:rgb(255, 255, 255);
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  li {
    position: relative;
    a {
      margin-left: -10px;
      color: #747E8B;
    }
  }
}

.dropright-menu {
  &:hover > ul {
    display: block;
  }
  > ul {
    background-color: #fff;
    box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
    display: none;
    min-width: 160px;
    position: absolute;
    list-style: none;
    top: 5px;
    margin: 0;
    padding: 0;
  }

  .material-icons {
    position: absolute;
    font-size: 16px;
    font-weight: normal;
    right: 10px;
  }
}

ul.menu, .menu li, .menu a{ 
  margin: 0; 
  padding: 0; 
  list-style:none; 
  text-decoration:none;
}

ul.menu ul{ 
  position: absolute; 
  display: none;
  width: 230px; 
}
ul.menu { 
  float:left;
  font-size:12px; 
  padding: 0px;
}

.menu li{ 
  float: left; 
  width: auto; 
  position: relative;
}

.menu li a{ 
  display:block; 
  padding:0 20px; 
  line-height:45px; 
  height:45px; 
  float:left; 
  transition:all 0.1s linear; 
}
.menu li:hover > ul.submenu{ 
  display:block; 
  top:45px;
  padding: 2px;
  z-index: 1;
}

.submenu{
  background-color: #fff;
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
}
.menu ul.submenu a{  
  background-color: #fff;
  border:none;
  width:230px !important;
  padding: 0px;  
}
.menu li:hover > ul.menu-submenu{ 
  display:block; 
  top:0; 
  left:230px; 
  padding: 2px; 
  width:230px;
}

.menu ul.menu-submenu a{  
  width:200px; 
  padding:0 20px;
 
}

.menu a{ color:#000;}
.menu li:hover > a{
  color:#fff;
}

.submenu a{
  color:#fff;
  background-color: #747E8B;
}
.submenu li:hover > a{ 
  background-color: map-get($theme-colors, "light"); 
}
 
.menu-submenu{
  background-color: #fff;
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
}
.menu-submenu a{color:#fff;}
.menu-submenu li:hover > a{ 
  background-color: map-get($theme-colors, "light"); 
}

@media (max-width: 992px) {
  .horizontal-menu.navbar-expand-lg .navbar-nav > li {
    padding: 0;
  }
  ul.menu, .menu li, .menu a{ 
    width: 230px;
  }
  ul.menu ul{ 
    display:none;
    width: 230px;
    padding: 30px;
  }
  .menu li a{ 
    transition:all 0.1s linear;
  }
  ul.menu ul{
    position: static;
  }
  .menu li:hover > ul.menu-submenu{ 
    padding: 0;
    margin-left: -200px;
    margin-top: 45px;
  }
}